// Override default color scheme from bootstrap/scss/_variables.scss
$gray-dark: #292b2c !default;
$gray: #464a4c !default;
$gray-light: #636c72 !default;
$gray-lighter: #eceeef !default;
$gray-lightest: #f7f7f9 !default;

$body-color: #25282e;
$body-bg: white;
$inverse-bg: $gray-dark !default;
$inverse-color: $gray-lighter !default;
$logo-red: #d20a11;

@import "bootstrap";
@import "font-awesome";
@import "latolatinfonts";
@import "andalus";
@import "justlefthand";

//font-family: 'Raleway', sans-serif;
//
//font-family: 'Montserrat', sans-serif;
//
//font-family: 'Nunito Sans', sans-serif;
//
//font-family: 'Hind Siliguri', sans-serif;
//
//font-family: 'Proza Libre', sans-serif;
//
//font-family: 'Yantramanav', sans-serif;
//@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans+Condensed');

.andalus {
  font-family: Andalus, AndalusWeb, $font-family-serif;
  font-size: 1.1rem;
  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }
  font-size-adjust: 0.59;
}

html {
  line-height: 1.5;
  font-size: 1rem;
  padding-bottom: 0;
  margin-bottom: 0;
  height: 100%;
}

body.home {
  background: linear-gradient(to bottom, rgba(228, 248, 255, 0.2), rgba(64, 138, 25, 0.71)),
  #8ec1e6;
  background-attachment: fixed;
}

main {
  margin-bottom: 2ex;
  margin-top: 2ex;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'LatoLatinWebMedium', $font-family-sans-serif;
  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
    letter-spacing: 0.05em;
  }
}

body {
  padding-top: 8ex;
  padding-bottom: 120px;
  position: relative;
  background-size: cover;
  min-height: 100%;
  @include media-breakpoint-up(md) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @include media-breakpoint-up(lg) {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }
}

div.greeter {
  text-align: center;
  transform: translateY(15%);

  h2, p {
    color: white;
    text-shadow: -3px 2px 0 #453b25, -8px 6px 0 #0a0e27;
  }

  .btn {
    text-shadow: none;
    margin: 0 2vw 0 0;
    //font-weight: 600;
  }
  .btn-primary {
    background-color: rgba(0, 0, 0, 0);;
    border-color: white;
    color: white;
  }
  .btn-primary:hover {
    background-color: darken($logo-red, 10%);
  }
  i {
    font-size: small;
    letter-spacing: -0.1em;
  }
}
