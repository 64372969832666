$fonts-root: '/fonts' !default;

@font-face {
  font-family: "JustLefthand";
  src: url($fonts-root + "/justlefthand.eot") format("eot");
  src: url($fonts-root + "/justlefthand.eot?#iefix") format("embedded-opentype"),
  url($fonts-root + "/justlefthand.woff") format("woff"),
  url($fonts-root + "/justlefthand.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
