$fonts-root: '/fonts' !default;

/* Webfont: LatoLatin-Black */@font-face {
    font-family: 'LatoLatinWebBlack';
    src: url($fonts-root + '/LatoLatin-Black.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-Black.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Black.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: auto;
}

/* Webfont: LatoLatin-BlackItalic */@font-face {
    font-family: 'LatoLatinWebBlack';
    src: url($fonts-root + '/LatoLatin-BlackItalic.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-BlackItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-BlackItalic.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-BlackItalic.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-BlackItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: auto;
}

/* Webfont: LatoLatin-Bold */@font-face {
    font-family: 'LatoLatinWeb';
    src: url($fonts-root + '/LatoLatin-Bold.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-Bold.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Bold.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
    text-rendering: auto;
}

/* Webfont: LatoLatin-BoldItalic */@font-face {
    font-family: 'LatoLatinWeb';
    src: url($fonts-root + '/LatoLatin-BoldItalic.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-BoldItalic.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-BoldItalic.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: bold;
    text-rendering: auto;
}

/* Webfont: LatoLatin-Hairline */@font-face {
    font-family: 'LatoLatinWebHairline';
    src: url($fonts-root + '/LatoLatin-Hairline.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-Hairline.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-Hairline.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Hairline.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Hairline.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: auto;
}

/* Webfont: LatoLatin-HairlineItalic */@font-face {
    font-family: 'LatoLatinWebHairline';
    src: url($fonts-root + '/LatoLatin-HairlineItalic.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-HairlineItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-HairlineItalic.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-HairlineItalic.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-HairlineItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: auto;
}

/* Webfont: LatoLatin-Heavy */@font-face {
    font-family: 'LatoLatinWebHeavy';
    src: url($fonts-root + '/LatoLatin-Heavy.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-Heavy.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Heavy.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Heavy.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: auto;
}

/* Webfont: LatoLatin-HeavyItalic */@font-face {
    font-family: 'LatoLatinWebHeavy';
    src: url($fonts-root + '/LatoLatin-HeavyItalic.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-HeavyItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-HeavyItalic.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-HeavyItalic.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-HeavyItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: auto;
}

/* Webfont: LatoLatin-Italic */@font-face {
    font-family: 'LatoLatinWeb';
    src: url($fonts-root + '/LatoLatin-Italic.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-Italic.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Italic.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: auto;
}

/* Webfont: LatoLatin-Light */@font-face {
    font-family: 'LatoLatinWebLight';
    src: url($fonts-root + '/LatoLatin-Light.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-Light.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Light.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: auto;
}

/* Webfont: LatoLatin-LightItalic */@font-face {
    font-family: 'LatoLatinWebLight';
    src: url($fonts-root + '/LatoLatin-LightItalic.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-LightItalic.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-LightItalic.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-LightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: auto;
}

/* Webfont: LatoLatin-Medium */@font-face {
    font-family: 'LatoLatinWebMedium';
    src: url($fonts-root + '/LatoLatin-Medium.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-Medium.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Medium.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: auto;
}

/* Webfont: LatoLatin-MediumItalic */@font-face {
    font-family: 'LatoLatinWebMedium';
    src: url($fonts-root + '/LatoLatin-MediumItalic.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-MediumItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-MediumItalic.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-MediumItalic.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-MediumItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: auto;
}

/* Webfont: LatoLatin-Regular */@font-face {
    font-family: 'LatoLatinWeb';
    src: url($fonts-root + '/LatoLatin-Regular.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-Regular.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Regular.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: auto;
}

/* Webfont: LatoLatin-Semibold */@font-face {
    font-family: 'LatoLatinWebSemibold';
    src: url($fonts-root + '/LatoLatin-Semibold.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-Semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-Semibold.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Semibold.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Semibold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: auto;
}

/* Webfont: LatoLatin-SemiboldItalic */@font-face {
    font-family: 'LatoLatinWebSemibold';
    src: url($fonts-root + '/LatoLatin-SemiboldItalic.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-SemiboldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-SemiboldItalic.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-SemiboldItalic.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-SemiboldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: auto;
}

/* Webfont: LatoLatin-Thin */@font-face {
    font-family: 'LatoLatinWebThin';
    src: url($fonts-root + '/LatoLatin-Thin.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-Thin.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Thin.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: auto;
}

/* Webfont: LatoLatin-ThinItalic */@font-face {
    font-family: 'LatoLatinWebThin';
    src: url($fonts-root + '/LatoLatin-ThinItalic.eot'); /* IE9 Compat Modes */
    src: url($fonts-root + '/LatoLatin-ThinItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url($fonts-root + '/LatoLatin-ThinItalic.woff2') format('woff2'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-ThinItalic.woff') format('woff'), /* Modern Browsers */
         url($fonts-root + '/LatoLatin-ThinItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: auto;
}

