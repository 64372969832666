$fonts-root: '/fonts' !default;

// “Bulletproof @font-face Syntax”
@font-face {
  font-family: "AndalusWeb";
  src: url($fonts-root + "/andalus.eot") format("eot");
  src: url($fonts-root + "/andalus.eot?#iefix") format("embedded-opentype"),
  url($fonts-root + "/andalus.woff") format("woff"),
  url($fonts-root + "/andalus.ttf") format("truetype"),
  url($fonts-root + "/andalus.svg#Andalus") format("svg");
  font-weight: normal;
  font-style: normal;
}
